import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/user';
import VueGtag from 'vue-gtag';

Vue.use(VueRouter);

const routes = [
  {
    path: '/top',
    name: 'Top',
    component: () => import('../views/Home.vue'),
    meta: { allowUnauthorizedUser: true },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { allowUnauthorizedUser: true },
  },
  {
    path: '/new',
    name: 'new',
    component: () => import('../views/New.vue'),
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('../views/MyPage.vue'),
  },
  {
    path: '/:id',
    redirect: '/:id/overview',
  },
  {
    path: '/:id/overview',
    name: 'overview',
    component: () => import('../views/Overview.vue'),
  },
  {
    path: '/:id/character',
    name: 'character',
    component: () => import('../views/Character.vue'),
  },

  {
    path: '/:id/phases',
    name: 'phases',
    component: () => import('../views/Phases.vue'),
  },
  {
    path: '/:id/opening',
    name: 'opening',
    component: () => import('../views/Opening.vue'),
  },
  {
    path: '/:id/ending',
    name: 'ending',
    component: () => import('../views/Ending.vue'),
  },
  {
    path: '/:id/discussion/:discussionId/handout',
    name: 'discussionHandout',
    component: () => import('../views/DiscussionHandout.vue'),
  },

  {
    path: '/:id/rooms',
    name: 'room',
    component: () => import('../views/Room.vue'),
  },
  {
    path: '/:id/character_sheet',
    name: 'character_sheet',
    component: () => import('../views/CharacterSheet.vue'),
  },
  {
    path: '/:id/vote',
    name: 'vote',
    component: () => import('../views/Vote.vue'),
  },
  {
    path: '/:id/character_timeline',
    name: 'character_timeline',
    component: () => import('../views/CharacterTimeline.vue'),
  },
  {
    path: '/:id/markdown/:markdownPhase/:markdownId',
    name: 'markdown',
    component: () => import('../views/Markdown.vue'),
  },
  {
    path: '/:id/markdown/:markdownPhase/:markdownId/handout',
    name: 'markdownHandout',
    component: () => import('../views/MarkdownHandout.vue'),
  },
  {
    path: '/:id/submit',
    name: 'submit',
    component: () => import('../views/Submit.vue'),
  },
  {
    path: '/:id/result',
    name: 'result',
    component: () => import('../views/Result.vue'),
  },
  {
    path: '/:id/resources',
    name: 'resources',
    component: () => import('../views/Resources.vue'),
  },
  {
    path: '/:id/role',
    name: 'role',
    component: () => import('../views/Role.vue'),
  },
  {
    path: '/:id/:markdownPhase',
    name: 'postMortem',
    component: () => import('../views/PostMortem.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, _from, savePosition) {
    if (savePosition) {
      return savePosition;
    }

    if (to.hash) {
      return {
        selector: '#next-user',
        offset: { x: 0, y: 100 },
      };
    }

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.allowUnauthorizedUser) ||
    store.getters.isSignedIn
  ) {
    next();
  } else {
    next({ name: 'Home' });
  }
});

router.onError(error => {
  if (/Loading chunk \d* failed./.test(error.message)) {
    window.location.reload();
  }
});

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID },
});
export default router;
