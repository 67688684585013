/* eslint-disable @typescript-eslint/ban-ts-comment */
import Vue from 'vue';
import LogRocket from 'logrocket';
import App from './App.vue';
import router from './router/index.js';
import firebase from '@/plugins/firebase';
import vuetify from './plugins/vuetify';
import Vuex from 'vuex';
import { rtdbPlugin } from 'vuefire';
import store from './store/user';
import './assets/main.scss';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

// logrocketでキャッチするため、vue由来のエラーをハンドリングする
// logrocketに送信するため、console.errorのメッセージにエラー内容を含める
Vue.config.errorHandler = (err, vm, info) => {
  console.error(`Vue Error Captured: ${info} ${err}`, err);
};

Vue.config.productionTip = false;

// @ts-ignore
Vue.use(firebase);
Vue.use(rtdbPlugin);

Vue.use(Vuex);

Sentry.init({
  Vue,
  enabled: process.env.VUE_APP_ENV != 'dev',
  environment: process.env.VUE_APP_ENV,
  dsn:
    'https://ce4c8b18d6774753a2f30be2143d03d2@o1130090.ingest.sentry.io/6174506',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'localhost',
        'dev.studio.uzu-app.com',
        'stg.studio.uzu-app.com',
        'studio.uzu-app.com',
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  // @ts-ignore
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
