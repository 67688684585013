<template>
  <div>
    <v-navigation-drawer
      v-if="gameId"
      v-model="drawer"
      app
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <v-list dense class="list">
        <v-list-item-group>
          <v-list-item link :to="{ name: 'overview', params: { id: gameId } }">
            <v-list-item-content>
              <v-list-item-title>作品概要</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'character', params: { id: gameId } }">
            <!--   <v-icon>mdi-menu-down</v-icon> -->
            <v-list-item-content>
              <v-list-item-title>キャラクター</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'character_sheet', params: { id: gameId } }"
            :disabled="gameData.characters ? false : true"
          >
            <v-list-item-icon style="margin-right: 0px"> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  gameData.characters ? null : 'gray--text text--lighten-3'
                "
                >キャラクターシート</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'character_timeline', params: { id: gameId } }"
            :disabled="gameData.characters ? false : true"
          >
            <v-list-item-icon style="margin-right: 0px"> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  gameData.characters ? null : 'gray--text text--lighten-3'
                "
                >キャラクタータイムライン</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'phases', params: { id: gameId } }">
            <!--   <v-icon>mdi-menu-down</v-icon> -->
            <v-list-item-content>
              <v-list-item-title>ゲームの流れ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'opening', params: { id: gameId } }">
            <v-list-item-icon style="margin-right: 0px"> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>オープニング</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'ending', params: { id: gameId } }">
            <v-list-item-icon style="margin-right: 0px"> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>エンディング</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'vote', params: { id: gameId } }">
            <v-list-item-icon style="margin-right: 0px">
              <!--  <v-icon>mdi-menu-right</v-icon> -->
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>投票</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'room', params: { id: gameId } }">
            <v-list-item-content>
              <v-list-item-title>ルーム</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'result', params: { id: gameId } }">
            <v-list-item-content>
              <v-list-item-title>真相テキスト</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'resources', params: { id: gameId } }">
            <v-list-item-content>
              <v-list-item-title>画像・BGM</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'submit', params: { id: gameId } }">
            <v-list-item-content>
              <v-list-item-title>作品を提出</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'role' }">
            <v-list-item-content>
              <v-list-item-title>権限管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            href="https://uzu-app.notion.site/2311c53f50f44cf592daadf678d76160"
            target="_blank"
          >
            <v-list-item-content>
              <v-list-item-title>
                よくある質問 <v-icon>mdi-open-in-new</v-icon></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            href="https://uzu-app.notion.site/6010419596bf465a9bc521abc90608e9"
            target="_blank"
          >
            <v-list-item-content>
              <v-list-item-title>
                ウズスタジオ利用規約
                <v-icon>mdi-open-in-new</v-icon></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app :clipped-left="$vuetify.breakpoint.lgAndUp">
      <v-app-bar-nav-icon
        v-if="gameId"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <img class="title" src="@/assets/uzu_studio_outline.svg" height="20" />
      <v-spacer />
      <v-menu v-if="isSignedIn" offset-y>
        <template #activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on">
            <img :src="photoURL" />
          </v-avatar>

          <!-- <v-icon large v-bind="attrs" v-on="on">mdi-menu</v-icon> -->
        </template>
        <v-list>
          <v-list-item to="/top">
            <v-list-item-title>
              <v-icon>mdi-folder-outline</v-icon>
              作成シナリオ一覧
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/mypage">
            <v-list-item-title>
              <v-icon>mdi-account-outline</v-icon>
              マイページ
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut">
            <v-list-item-title>
              <v-icon>mdi-exit-to-app</v-icon>
              ログアウト
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div v-else-if="processing" class="processing-wrapper">
        <Loading />
      </div>
      <v-toolbar-items v-else class="outer">
        <div class="inner">
          <v-btn class="primary white--text hidden-xs-only" @click="signIn">
            新規登録・ログイン (Google)
          </v-btn>
          <v-btn class="primary white--text hidden-sm-and-up" @click="signIn">
            新規登録・ログイン
          </v-btn>
        </div>
      </v-toolbar-items>
    </v-app-bar>

    <slot></slot>
  </div>
</template>

<script>
  import store from '../store/user';
  import Auth from '../plugins/auth';
  import Loading from './atoms/Loading.vue';
  import { mapGetters } from 'vuex';

  import Vue from 'vue';
  export default Vue.extend({
    name: 'CustomHeader',
    components: { Loading },
    data: () => ({ drawer: null, processing: false }),
    computed: {
      ...mapGetters({
        photoURL: 'photoURL',
        uid: 'uid',
        isSignedIn: 'isSignedIn',
        gameData: 'gameData',
      }),
      game() {
        return this.gameData;
      },
      gameId: function() {
        return this.$route.params.id;
      },
    },
    methods: {
      toPage(view) {
        this.$router.push({
          name: view,
          params: { id: this.$route.params.id },
        });
      },
      async signIn() {
        this.processing = true;
        await Auth.login();
        this.processing = false;
      },
      signOut() {
        Auth.logout();
      },
    },
  });
</script>
<style lang="scss">
  .processing-wrapper {
    width: 40px;
    height: 40px;
    position: relative;
    font-size: 27px;
  }
</style>
