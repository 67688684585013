import firebase from 'firebase';
import '@firebase/auth';
import UserEmailList from '../assets/mailHash.json';
import store from '../store/user';
import * as Sentry from '@sentry/browser';
export const db = firebase.database();

async function setTrackingTools(user) {
  const db = firebase.database();
  const snapshot = await db.ref(`users/${user.uid}/name`).once('value');
  let penname = null;
  if (snapshot.exists()) {
    penname = snapshot.val();
  }

  Sentry.setUser({
    id: user.uid,
    email: user.email,
    username: penname,
  });
  Sentry.setTag('penname', penname);
}

function setGATag(gtag, user) {
  gtag.set({
    user_id: user.uid,
  });
}

async function sha256(text) {
  const uint8 = new TextEncoder().encode(text);
  const digest = await crypto.subtle.digest('SHA-256', uint8);
  return Array.from(new Uint8Array(digest))
    .map(v => v.toString(16).padStart(2, '0'))
    .join('');
}

async function validUser(user) {
  return UserEmailList.emails.includes(await sha256(user?.email));
}

export default {
  async login() {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then(async result => {
        if (!(await validUser(result.user))) {
          store.commit('onAuthStateChanged', null);
          store.dispatch('unbindUser');
          console.log("Don't have a permission");
          alert(
            '権限が付与されていません。もし運営に申し込んでいる場合は、数分してから再度お試しください。権限を申し込むには、「ウズスタジオ」で検索し、記事内にあるお申し込みフォームへの回答をお願いいたします。'
          );
          return;
        }
        store.commit('onAuthStateChanged', result.user);
        store.dispatch('bindUser', result.user.uid);
        if (!db.ref(`users/` + result.user.uid).once('value')) {
          db.ref(`users/` + result.user.uid)
            .set({
              name: '未設定',
            })
            .then(() => {
              console.log('success!');
              return true;
            });
        }
        setTrackingTools(result.user);
      })
      .catch(error => {
        console.log(error);
      });
  },
  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        store.commit('onAuthStateChanged', null);
        store.dispatch('unbindUser');
        console.log('ログアウト成功！');
      })
      .catch(error => {
        // Handle Errors here.
        console.log(error.code);
      });
  },
  onAuth(gtag) {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || !validUser(user)) {
        store.commit('onAuthStateChanged', null);
        store.dispatch('unbindUser');
        return;
      }
      store.commit('onAuthStateChanged', user);
      store.dispatch('bindUser', user.uid);
      setTrackingTools(user);
      setGATag(gtag, user);
    });
  },
};
